// import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import Container from "../../components/container";
import { H3UppercaseGrey, H4UppercaseGrey } from "../../components/headings";
import { Layout } from "../../components/layout";
import { GridTwo, GridOneText } from "../../components/grids";
import { HeroButtonWhite, HeroButtonDark } from "../../components/buttons";
import styled from "styled-components";

import Logo from "../../images/Logo.png";

export default function Home({ data }) {
    const options = {
        renderNode: {
            [BLOCKS.HEADING_4]: (node, children) => (
                <H4UppercaseGrey>{children}</H4UppercaseGrey>
            ),
        },
        renderText: (text) => {
            return text.split("\n").reduce((children, textSegment, index) => {
                return [
                    ...children,
                    index > 0 && <br key={index} />,
                    textSegment,
                ];
            }, []);
        },
    };
    const homeData = data.allContentfulHomepage.nodes[0];
    const TextDiv = styled.div`
        padding: 80px;
        text-align: right;

        @media screen and (max-width: 813px) {
            text-align: right;
            padding: 30px;
            font-size: 0.9rem;
        }
    `;
    const CenterTextDiv = styled.div`
        font-size: 1rem;
        @media screen and (max-width: 813px) {
            font-size: 0.9rem;
        }
    `;
    const PartnerContainer = styled.div`
        display: inline-block;

        a {
            text-decoration: none;
        }

        @media screen and (max-width: 813px) {
            display: block;
        }
    `;
    const Partner = styled.div`
        height: auto;
        padding: 20px !important;
        max-width: 450px;
        width: 23vw;
        min-width: 400px;
        position: relative;
        img {
            margin: 0;
        }

        @media screen and (max-width: 813px) {
            width: 100vw;
            margin: 0 auto;
            min-width: unset;
        }

        #toleft {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #fff;
            color: #333;
            overflow: hidden;
            width: 0;
            height: 100%;
            transition: all 0.5s ease;
            text-align: right;

            @media screen and (max-width: 813px) {
                position: unset;
                display: block;
                width: 100%;
                padding-top: 15px;
                &:hover {
                    width: 100%;
                    left: 0;
                }
            }
        }

        #toright {
            position: absolute;
            bottom: 0;
            left: 100%;
            right: 0;
            background-color: #fff;
            color: #333;
            overflow: hidden;
            width: 0;
            height: 100%;
            transition: 0.5s ease;
            text-align: left;
            z-index: 100;

            @media screen and (max-width: 813px) {
                position: unset;
                display: block;
                width: 100%;

                &:hover {
                    width: 100%;
                    left: 0;
                }
            }
        }

        :hover {
            #toleft {
                width: 100%;
                left: -100%;
            }

            #toright {
                width: 100%;
            }
        }
    `;
    const Description = styled.div`
        height: 100%;
        width: 23vw;
        max-width: 450px;
        min-width: 400px;

        p {
            margin: 0;
            padding: 10% 10% 0% 10%;
            text-decoration: none;
        }

        #author {
            float: right;
            padding-right: 5%;
        }

        @media screen and (max-width: 813px) {
            width: 100%;
            min-width: unset;

            p {
                padding: 5%;
            }

            #author {
                padding-right: 3%;
            }
        }
    `;
    const BgSources = [
        homeData.headerBackground.mobile,
        {
            ...homeData.headerBackground.desktop,
            media: `(min-width: 1150px)`,
        },
    ];
    const SideSources = [
        homeData.twoGridPicture.mobile,
        {
            ...homeData.twoGridPicture.desktop,
            media: `(min-width: 1150px)`,
        },
    ];
    return (
        <Layout title="Home" lang="en" actSite="">
            <Container
                image={BgSources}
                centered={
                    <div>
                        {homeData.headerText}
                        <br />
                        <HeroButtonWhite
                            to="/en/about/"
                            text="Read more..."
                        ></HeroButtonWhite>
                    </div>
                }
            />
            <GridTwo
                left={
                    <TextDiv>
                        {documentToReactComponents(
                            homeData.twoGridText.json,
                            options
                        )}
                    </TextDiv>
                }
                right={
                    <Img
                        fluid={SideSources}
                        style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                }
            />
            <div style={{ textAlign: "center", paddingTop: "40px" }}>
                <H4UppercaseGrey>Our Premium-Partners</H4UppercaseGrey>
                <div style={{ margin: "0 auto", width: "auto" }}>
                    <PartnerContainer style={{ background: "#333" }}>
                        <a
                            href="https://www.schueco.com/de/"
                            title="Schueco Website"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <Partner>
                                <Img
                                    fluid={
                                        data.schuecologo.nodes[0]
                                            .childImageSharp.fluid
                                    }
                                    imgStyle={{ transition: "opacity 2s ease" }}
                                />
                                <div
                                    id="toright"
                                    style={{
                                        color: "#FFFFFF",
                                        background: "#333333",
                                    }}
                                >
                                    <Description>
                                        <p style={{ color: "#FFF" }}>
                                            The reliable and long-standing
                                            cooperation enables us to realise
                                            special projects together.
                                        </p>
                                        <div
                                            id="author"
                                            style={{
                                                textAlign: "right",
                                                color: "#FFFFFF",
                                                background: "#333333",
                                            }}
                                        >
                                            {" "}
                                            - Waldemar Maier, CEO
                                        </div>
                                    </Description>
                                </div>
                            </Partner>
                        </a>
                    </PartnerContainer>
                    <PartnerContainer>
                        <a
                            href="https://www.fuchswacker.de/"
                            title="Fuchs, Wacker. Website"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <Partner>
                                <Img
                                    fluid={
                                        data.fuchswacker.nodes[0]
                                            .childImageSharp.fluid
                                    }
                                    imgStyle={{ transition: "opacity 2s ease" }}
                                />
                                <div id="toleft">
                                    <Description>
                                        <p style={{ color: "#333" }}>
                                            The trusting and competent
                                            cooperation inspires me again and
                                            again!
                                            <br />
                                        </p>
                                        <div
                                            id="author"
                                            style={{ color: "#333" }}
                                        >
                                            {" "}
                                            - Stephan Fuchs, Architect
                                        </div>
                                    </Description>
                                </div>
                            </Partner>
                        </a>
                    </PartnerContainer>
                </div>
            </div>
            <GridOneText>
                <CenterTextDiv>
                    <H3UppercaseGrey>Please contact us!</H3UppercaseGrey>
                    <h5>We are happy to be there for you.</h5>
                    <p>
                        We value our customers very highly and are pleased that
                        you are visiting our website. What can we do for you?
                    </p>
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            alignContent: "center",
                        }}
                    >
                        <div
                            style={{
                                margin: "0 50px 10px 50px",
                                flex: "1 1 25ch",
                            }}
                        >
                            <h5>Kessler Windows Europe</h5>
                            <p>
                                Sautierstraße 23
                                <br />
                                78187 Geisingen, German
                            </p>
                            <p>
                                Tel:{" "}
                                <a href="tel:+497704927990">
                                    +49 (0)7704 92799 0
                                </a>
                                <br />
                                Mail:{" "}
                                <a href="mailto:info@kessler-metall.de">
                                    info@kessler-metall.de
                                </a>
                            </p>
                            {/* {documentToReactComponents(homeData.centeredText.json, options)} */}
                        </div>
                        <div
                            style={{
                                margin: "0 50px 10px 50px",
                                flex: "1 1 25ch",
                            }}
                        >
                            <h5>Kessler Windows South Africa</h5>
                            <p>
                                Regional Representative:
                                <br />
                                <strong>Hans-Joerg Kruchen</strong>
                            </p>
                            <p>
                                Tel.:{" "}
                                <a href="tel:+27826555555">+27 82 655 5555</a>
                                <br />
                                Mail:{" "}
                                <a href="mailto:sales@kessler-windows.co.za">
                                    sales@kessler-windows.co.za
                                </a>
                            </p>
                            {/* {documentToReactComponents(homeData.centeredText.json, options)} */}
                        </div>
                    </div>
                    <HeroButtonDark
                        to="/en/about/"
                        text="About us ..."
                    ></HeroButtonDark>
                </CenterTextDiv>
            </GridOneText>
            <div style={{ padding: "0", margin: "0", height: "600px" }}>
                <iframe
                    title="Destinations to Kessler Metall"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2673.5691469193293!2d8.651911315832406!3d47.92537357389534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x41085f33368a55ef%3A0xc8e83648009f5514!2sMetallbau%20Kessler%20GmbH%20%26%20Co.%20KG!5e0!3m2!1sde!2sde!4v1596457455812!5m2!1sde!2sde"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    style={{ border: "none", margin: 0, padding: 0 }}
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                ></iframe>
            </div>
            <script type="application/ld+json">
                {`{
                "@context": "https://schema.org",
                "@type": "LocalBusiness",
                "name": "Metallbau Kessler",
                "image": "https://www.kessler-metall.de/${Logo}",
                "@id": "https://www.kessler-metall.de/",
                "url": "https://www.kessler-metall.de/",
                "telephone": "+497704927990",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Sautierstraße 23",
                    "addressLocality": "Geisingen",
                    "postalCode": "78166",
                    "addressCountry": "DE"
                },
                "openingHoursSpecification": [
                    {
                    "@type": "OpeningHoursSpecification",
                    "dayOfWeek": [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday"
                    ],
                    "opens": "07:00",
                    "closes": "17:00"
                    }
                ]}`}
            </script>
        </Layout>
    );
}

// Query bg_big.webp and sea_slidingdoor.webp for lazy loading
export const query = graphql`
    query {
        allContentfulHomepage(filter: { node_locale: { eq: "en" } }) {
            nodes {
                twoGridPicture {
                    desktop: fluid(maxWidth: 1150, quality: 90) {
                        src
                        ...GatsbyContentfulFluid_withWebp
                    }
                    mobile: fluid(maxWidth: 600, quality: 90) {
                        src
                        ...GatsbyContentfulFluid_withWebp
                    }
                    title
                }
                node_locale
                headerBackground {
                    desktop: fluid(maxWidth: 2300, quality: 90) {
                        src
                        ...GatsbyContentfulFluid_withWebp
                    }
                    mobile: fluid(maxWidth: 1150, quality: 90) {
                        src
                        ...GatsbyContentfulFluid_withWebp
                    }
                    title
                }
                twoGridText {
                    json
                }
                centeredText {
                    json
                }
                headerText
            }
        }
        fuchswacker: allFile(filter: { name: { eq: "FW_Logo_small" } }) {
            nodes {
                childImageSharp {
                    fluid {
                        src
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
        schuecologo: allFile(filter: { name: { eq: "Schueco_Logo_small" } }) {
            nodes {
                childImageSharp {
                    fluid {
                        src
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    }
`;
